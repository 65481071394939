import {
  CSRF_URL,
  ROOT,
  ROOT_NOHTTPS,
  SUBREQUESTS_URL,
  customerCredentials as config,
  REGISTER_USER_URI,
  OAUTH_TOKEN_URI,
  USERS_URI,
  DOGGY_URI,
  PROFILE_URI,
  USER_URI,
  WEBFORM_SUBMISSIONS_IDS_URI,
  WEBFORM_SUBMISSIONS_URI
} from "../../config";
import {
  transferDataWithSubrequests,
  transferDataWithSubrequestsAxios
} from "../../requests";
import {
  SET_TOKEN,
  SET_USER_INFO,
  SET_USER_DOGGIES,
  ADD_DOGGY_TO_DOGGIES,
  EDIT_DOGGY_FROM_DOGGIES,
  REMOVE_DOGGY_FROM_DOGGIES,
  DELETE_TOKEN,
  DELETE_USER_DOGGIES,
  DELETE_USER_INFO,
  SET_HISTORIAL_ORDENES,
  DELETE_HISTORIAL_ORDENES
} from "./actionTypes";
import axios from "axios";

const addInfoToTokenAndMakeItPersist = token => {
  // adds date and expiration date to provided token, sets it in localStorage and returns enhances token
  token = Object.assign({}, token);
  token.date = Math.floor(Date.now() / 1000);
  token.expirationDate = token.date + token.expires_in;
  localStorage.setItem("token", JSON.stringify(token));
  return token;
};

export const fetchOauthToken = (url, username, password) => dispatch =>
  new Promise((resolve, reject) => {
    // fetches oauth token and returns it
    let formData = new FormData();
    formData.append("grant_type", "password");
    formData.append("client_id", config.oauth.client_id);
    formData.append("client_secret", config.oauth.client_secret);
    // formData.append("scope", config.oauth.scope);
    formData.append("username", username);
    formData.append("password", password);

    fetch(url, {
      method: "post",
      headers: new Headers({
        Accept: "application/json"
      }),
      body: formData
    })
      .then(response => {
        resolve(response.json());
      })
      .catch(err => {
        console.log("error en fetchoauthToken", err);
        reject();
      });
  });

export const fetchAndSetOauthToken = (url, username, password) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchOauthToken(url, username, password)).then(data => {
      // console.log("oauthToken data", data);
      // if request returns error:
      if (data.error) {
        console.log("Error retrieving token in fetchAndSetOauthToken", data);
        //TODO: crear un reducer para errores
        localStorage.removeItem("token");
        dispatch({ type: DELETE_TOKEN });
        reject();
        return;
      }
      // if request is ok:
      const token = addInfoToTokenAndMakeItPersist(data);
      dispatch({ type: SET_TOKEN, payload: token });

      resolve(token);
    });
  });

export const refreshAndSetOauthToken = (url, token) => dispatch =>
  new Promise((resolve, reject) => {
    localStorage.removeItem("token");

    console.log("Getting refresh token...");
    if (token !== null) {
      let formData = new FormData();
      formData.append("grant_type", "refresh_token");
      formData.append("client_id", config.oauth.client_id);
      formData.append("client_secret", config.oauth.client_secret);
      // formData.append("scope", config.oauth.scope); --> lo quitamos porque hacia que no sirviera
      formData.append("refresh_token", token.refresh_token);

      fetch(url, {
        method: "post",
        headers: new Headers({
          Accept: "application/json"
        }),
        body: formData
      })
        .then(response => {
          return response.json();
        })

        .then(data => {
          // console.log("response from refreshoauthtoken", data);
          if (data.error) {
            console.log(
              "Error retrieving token in refreshAndSetOauthToken",
              data
            );
            //TODO: crear un reducer para errores
            localStorage.removeItem("token");
            dispatch({ type: DELETE_TOKEN });
            reject();
            return;
          }
          const token = addInfoToTokenAndMakeItPersist(data);
          // console.log(token);
          dispatch({ type: SET_TOKEN, payload: token });
        })
        .then(() => {
          resolve();
        })
        .catch(err => {
          dispatch({ type: DELETE_TOKEN });
          console.log("error al momento de refreshAndSetOauthToken", err);
          reject();
        });
    }
  });

export const fetchAndSetUserAndDoggiesData = token => dispatch =>
  new Promise((resolve, reject) => {
    // NOTA: por ahora se fetch la info de auth del usuario (user) y la que se puede cambiar (profile)
    // las siguientes constantes se van a usar para hacer el request de info y tambien para parsearla
    // console.log("token in fetchuseranddoggiesdata", token);
    const getPerritosReqId = "get-perritos";
    const getUserReqId = "get-user-id";
    const getProfileReqId = "get-profile-id";
    const getOrdersIdsReqId = "get-ids-from-orders";
    const getOrdersReqId = "get-orders";

    const headers = {
      Accept: "application/vnd.api+json",
      Authorization: `${token.token_type} ${token.access_token}`
    };
    // el body: 1) obtiene el ID del usuario (2) usa el ID del usuario para obtener su info, (3) obtiene la info de los perritos (la info de username viene en la request del usuario)
    const body = JSON.stringify([
      {
        requestId: getUserReqId,
        uri: "/api",
        action: "view",
        headers
      },
      {
        requestId: getPerritosReqId,
        uri:
          "/api/node/doggy?filter%5Bauthor-filter%5D%5Bcondition%5D%5Bpath%5D=uid.id&filter%5Bauthor-filter%5D%5Bcondition%5D%5Bvalue%5D={{get-user-id.body@$.meta.links.me.meta.id}}&include=uid,image&jsonapi_include=1",
        action: "view",
        waitFor: [getUserReqId],
        headers
      },
      {
        requestId: getProfileReqId,
        uri:
          "/api/node/profile?filter%5Bauthor-filter%5D%5Bcondition%5D%5Bpath%5D=uid.id&filter%5Bauthor-filter%5D%5Bcondition%5D%5Bvalue%5D={{get-user-id.body@$.meta.links.me.meta.id}}&include=uid,image&jsonapi_include=1",
        action: "view",
        waitFor: [getUserReqId],
        headers
      },
      {
        requestId: getOrdersIdsReqId,
        uri: `${WEBFORM_SUBMISSIONS_IDS_URI}?sort=-created`,
        action: "view",
        headers
      },

      {
        requestId: getOrdersReqId,
        uri: `${WEBFORM_SUBMISSIONS_URI}/{{${getOrdersIdsReqId}.body@$.data[*].attributes.drupal_internal__sid}}`,
        action: "view",
        waitFor: [getOrdersIdsReqId],
        headers: {
          Accept: "application/json",
          Authorization: `${token.token_type} ${token.access_token}`
        }
      }
    ]);

    //  {{[subrequest del paso 1]@$.data[*].attributes.drupal_internal__sid}}
     console.log("COPY HERE: ", body);
    // request data

    transferDataWithSubrequests(SUBREQUESTS_URL, body).then(res => {
      // si hubo un error con la token (inválida), eliminarla
      // console.log("get-user-id-resp", JSON.parse(res[getUserReqId].body));
      // console.log("get-perritos", JSON.parse(res["get-perritos#uri{0}"].body));
      // console.log(
      //   `get orders (ejemplo de cada uno)`,
      //   JSON.parse(res["get-orders#uri{0}"].body)
      // );
      // console.log(
      //   `get-ids-from-orders: `,
      //   JSON.parse(res["get-ids-from-orders"].body)
      // );
      console.log("response from user and doggies data", res);

      if (res[getUserReqId].headers.status.includes(401)) {
        localStorage.getItem("token") && localStorage.removeItem("token");
        dispatch({ type: DELETE_TOKEN });
        reject();
        return;
      }

      let formattedResponse = { orders: [] };
      Object.entries(res).forEach(item => {
        // incluye información de los doggies en la respuesta
        if (item[0].includes(getPerritosReqId))
          formattedResponse = {
            ...formattedResponse,
            doggies: JSON.parse(item[1].body).data
          };
        // incluye información de los doggies en la respuesta
        if (item[0].includes(getProfileReqId))
          formattedResponse = {
            ...formattedResponse,
            profileInfo: JSON.parse(item[1].body).data[0]
          };
        if (item[0].includes(getOrdersReqId)) {
          formattedResponse = {
            ...formattedResponse,
            orders: [...formattedResponse.orders, JSON.parse(item[1].body).data]
          };
        }
      });
      console.log(
        "get user data with subrequests (formatted)",
        formattedResponse
      );
      // cambiar los nombres de los fields para que coincidan con los que tenemos en react
      // console.log("formattedResponse", formattedResponse);
      // transform data to be included in redux
      const profileInfo = profileTransform(formattedResponse.profileInfo);
      // console.log("profileInfo", profileInfo);
      const userDoggies = formattedResponse.doggies.map(doggy => {
        return doggyTransform(doggy);
      });

      const historialOrdenes = formattedResponse.orders;

      dispatch({ type: SET_HISTORIAL_ORDENES, payload: historialOrdenes });
      dispatch({ type: SET_USER_INFO, payload: profileInfo });
      dispatch({ type: SET_USER_DOGGIES, payload: userDoggies });

      resolve();
    });
  });

export const updateUserAndResetToken = (token, state, id) => dispatch =>
  new Promise((resolve, reject) => {
    /**   
     * @desc: esta función se usa sólo para cuando se quiere cambiar información de la base que maneja autenticación.
    tiene que hacerse el reset del token porque se revoca cuando se hacen cambios en el perfil. Por eso es que se pide la contraseña
    en caso de que falle la validación, devuelve un 'incorrectCredentials' o 'emailAlreadyTaken'
     *  */

    // console.log({ token, state, id });
    // console.log("INSIDE UPDATE USER");
    const getCSRFTokenReqId = "get-csrf-token";
    const getUserDataReqId = "get-user-data";
    const patchUserDataReqId = "patch-user-data";
    const newOauthTokenReqId = "fetch-oauth-token";

    const getUserDataUri = `${USERS_URI}/${id}`;
    // se necesita proveer el nuevo mail (en caso de que se haya cambiado)
    const oAuthTokenUri = `${OAUTH_TOKEN_URI}?grant_type=password&client_id=c165476e-a3cf-4c25-8906-f23062f73ad8&client_secret=admin&username=${
      state.mail
    }&password=${
      state.newPassword !== "" ? state.newPassword : state.currentPassword
    }`; // se proporciona la nueva password sólo en el caso en el que ésta se cambió (porque ste request se hace una vez que ya están modificados los datos del usuario)
    const patchUserDataUri = `${USER_URI}`;
    const patchUserDataBody = userTransform(state);

    const body = JSON.stringify([
      // get csrf token
      {
        requestId: getCSRFTokenReqId,
        uri: "/rest/session/token",
        action: "view"
      },
      // get user data from /api/users/userId to get user internal id
      {
        requestId: getUserDataReqId,
        uri: getUserDataUri,
        headers: {
          "Content-Type": "application/vnd.api+json",
          "X-CSRF-Token": `{{${getCSRFTokenReqId}.body}}`,
          Accept: "application/vnd.api+json",
          Authorization: `${token.token_type} ${token.access_token}`
        },
        waitFor: [getCSRFTokenReqId],
        action: "view"
      },
      // get (and patch) user data from /user/internalId
      {
        requestId: patchUserDataReqId,
        uri: `${patchUserDataUri}/{{${getUserDataReqId}.body@$.data.attributes.internalId}}`,
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": `{{${getCSRFTokenReqId}.body}}`,
          Accept: "application/json",
          Authorization: `${token.token_type} ${token.access_token}`
        },
        body: JSON.stringify(patchUserDataBody),
        waitFor: [getUserDataReqId],
        action: "update"
      },
      {
        requestId: newOauthTokenReqId,
        uri: oAuthTokenUri,
        headers: {
          Accept: "application/json"
        },
        waitFor: [patchUserDataReqId],
        action: "create"
      }
    ]);
    // console.log(JSON.parse(body));

    transferDataWithSubrequestsAxios(SUBREQUESTS_URL, body)
      .then(res => {
        // handle el caso donde el correo ya ha sido tomado
        console.log("res", res);
        if (
          res.data["patch-user-data#uri{0}"].headers.status.includes(422) &&
          res.data["patch-user-data#uri{0}"].body.includes("already taken")
        ) {
          throw Error("emailAlreadyTaken");
        }
        // handle el caso donde se proporcionó una contraseña incorrecta
        if (
          res.data["patch-user-data#uri{0}"].headers.status.includes(422) &&
          res.data["patch-user-data#uri{0}"].body.includes(
            "password is missing"
          )
        ) {
          throw Error("incorrectCredentials");
        }
        // console.log("llegó aquí");
        // console.log("res", res);
        // console.log(
        //   getUserDataReqId,
        //   JSON.parse(res.data[getUserDataReqId].body)
        // );
        // console.log(
        //   patchUserDataReqId,
        //   JSON.parse(res.data["patch-user-data#uri{0}"].body)
        // );
        let token = JSON.parse(res.data[newOauthTokenReqId].body);
        // console.log("token", token);
        token = addInfoToTokenAndMakeItPersist(token);
        dispatch({ type: SET_TOKEN, payload: token });
        resolve({ token, message: "ok" }); // se resuelve con un objecto vacío para que no interfiera con el error handler de quien llama a la función
      })
      .catch(err => {
        console.log("error while trying to update user info", err);
        resolve(err);
      });
  });

export const postOrPatchUserOrDoggiesData = (
  token,
  sendData,
  type,
  id
) => dispatch =>
  // NOTA IMPORTANTE: hay que cambiar la url para que apunte a profiles/customer
  // sólo para cuando se quiere trabajar con info que no es de autenticación
  // token:  OAuthToken
  // type = 'postUser' | 'patchUser' | 'postDoggy' | 'patchDoggy'
  //updateData es un objeto (no strinfiied, porque eso se hace en )
  // id sólo se proporciona cunado es de tipo 'patchUser' o 'patchDoggy' --> checar si el id que se manda es el del usuario o del customer profile que está realcionado con él (en principio, sólo debe haber un customer profile por cada usuario)
  // esta función se manda llamar cuando see quiere registrar o editar un usuario o un doggy
  new Promise(resolve => {
    // console.log("inside post or patch user or doggies data");
    // define nombres de las requests y variables que se usarán en éstas
    const getCSRFTokenReqId = "get-csrf-token";
    const mainReqId = "main-request";
    // afterReqId se va a encargar de pedir la información del usuario o perrito que se acaban de editar o registrar
    const afterReqId = "after-request";

    let mainReqUri =
      type === "postDoggy" || type === "patchDoggy" ? DOGGY_URI : PROFILE_URI;
    if (type === "patchUser" || type === "patchDoggy") {
      mainReqUri = `${mainReqUri}/${id}`;
    }
    const afterReqUri =
      type === "postDoggy" || type === "patchDoggy" ? DOGGY_URI : PROFILE_URI;
    const mainReqAction =
      type === "postUser" || type === "postDoggy" ? "create" : "update";

    // console.log({token, sendData, type});
    const body = JSON.stringify([
      {
        requestId: getCSRFTokenReqId,
        uri: "/rest/session/token",
        action: "view"
      },
      {
        requestId: mainReqId,
        uri: mainReqUri,
        body: JSON.stringify(sendData),
        headers: {
          "Content-Type": "application/vnd.api+json",
          "X-CSRF-Token": `{{${getCSRFTokenReqId}.body}}`,
          Accept: "application/vnd.api+json",
          Authorization: `${token.token_type} ${token.access_token}`
        },
        waitFor: [getCSRFTokenReqId],
        action: mainReqAction
      },
      // se devuelve el doggy recién creado para incluirlo en los doggies del usuario
      {
        requestId: afterReqId,
        uri: `${afterReqUri}/{{${mainReqId}.body@$.data.id}}?include=uid,image&jsonapi_include=1`,
        waitFor: [mainReqId],
        headers: {
          Accept: "application/vnd.api+json",
          Authorization: `${token.token_type} ${token.access_token}`
        },
        action: "view"
      }
    ]);
    // console.log(JSON.parse(body));
    // console.log(body);
    // console.log(
    //   `${mainReqUri}/{{${mainReqId}.body@$.data.id}}?include=uid,image`
    // );
    // console.log(JSON.parse(body));
    // console.log(body);
    // console.log(SUBREQUESTS_URL);
    transferDataWithSubrequests(SUBREQUESTS_URL, body)
      // con lo anterior ya se postea en el back a la entity (ahora sólo queda manejar el resultado en el front)
      .then(res => {
        // console.log(res);
        // console.log("body:", res[getCSRFTokenReqId].body);
        // console.log("body:", JSON.parse(res[mainReqId].body));
        // console.log("body:", JSON.parse(res[afterReqId].body));
        // console.log(JSON.parse(res["after-request#uri{0}"].body));
        let resEntity;
        // buscamos el elemnto de la response donde se tiene la info del perrito o usuario
        Object.entries(res).forEach(item => {
          if (item[0].includes(afterReqId)) {
            resEntity = JSON.parse(item[1].body).data;
          }
        });
        if (type === "postDoggy" || type === "patchDoggy")
          console.log("todo bien hasta este punto", resEntity);
          return doggyTransform(resEntity);
        if (type === "postUser" || type === "patchUser")
          return profileTransform(resEntity);
      })
      .then(item => {
        // console.log(item);
        switch (type) {
          case "postDoggy":
            dispatch({ type: ADD_DOGGY_TO_DOGGIES, payload: item });
            break;
          case "patchDoggy":
            dispatch({ type: EDIT_DOGGY_FROM_DOGGIES, payload: item });
            break;
          case "patchUser":
            dispatch({ type: SET_USER_INFO, payload: item });
            break;
          default:
        }
        resolve();
      })
      .catch(err => {
        console.log("error", err);
      });
    // console.log("hola desde writeUserAndDoggies");
  });

export const uploadImage = (url, imageFile, token) =>
  new Promise((resolve, reject) => {
    getCSRFToken(token).then(csrf => {
      // console.log(imageFile);
      axios({
        url: url,
        method: "post",
        data: imageFile,
        headers: {
          "Content-Type": "application/octet-stream",
          "X-CSRF-Token": csrf,
          Accept: "application/vnd.api+json",
          "Content-Disposition": `file; filename="${imageFile.name}"`,
          Authorization: `${token.token_type} ${token.access_token}`
        }
      })
        .then(image => {
          // console.log(image);
          resolve(image);
        })
        .catch(err => {
          console.log("there was an error in image upload");
          reject(err);
        });
    });
  });

export const deleteEntity = (id, type, token) => dispatch => {
  // type: 'doggy'|'user'
  new Promise((resolve, reject) => {
    const getCSRFTokenReqId = "get-csrf-token";
    const mainReqId = "main-request";

    const mainReqUri =
      type === "doggy" ? `/api/node/doggy/${id}` : `/api/users/${id}`;

    const body = JSON.stringify([
      { requestId: getCSRFTokenReqId, uri: CSRF_URL, action: "view" },
      {
        requestId: mainReqId,
        uri: mainReqUri,
        waitFor: [getCSRFTokenReqId],
        headers: {
          "Content-Type": "application/vnd.api+json",
          "X-CSRF-Token": `{{${getCSRFTokenReqId}.body}}`,
          Accept: "application/vnd.api+json",
          Authorization: `${token.token_type} ${token.access_token}`
        },
        action: "delete"
      }
    ]);
    transferDataWithSubrequests(SUBREQUESTS_URL, body)
      .then(res => {
        // console.log(res);
      })
      .then(() => {
        if (type === "doggy") {
          dispatch({ type: REMOVE_DOGGY_FROM_DOGGIES, payload: id });
        }
        if (type === "user") {
          console.log("programar el caso para eliminar un usuario");
        }
      })
      .then(resolve())
      .catch(err => {
        console.log("error en el momento de tratar de eliminar entity", err);
      });
  });
};

export const logout = () => dispatch => {
  localStorage.getItem("token") && localStorage.removeItem("token");
  dispatch({ type: DELETE_TOKEN });
  dispatch({ type: DELETE_USER_INFO });
  dispatch({ type: DELETE_USER_DOGGIES });
  dispatch({ type: DELETE_HISTORIAL_ORDENES });
  // simplemente se quita el token de redux y el local state
};

export const registerUser = state => async dispatch => {
  const getCSRFTokenReqId = "get-csrf-token";
  const registerUserReqId = "register-user";
  const getOauthTokenReqId = "get-oauth-token";
  let createUserRes;

  // create new user adn parse data from response
  //eslint-disable-next-line
  let csrfToken;
  let newUser;
  let oAuthToken;
  let uuid;

  const userBody = JSON.stringify({
    _links: {
      type: {
        href: `${ROOT_NOHTTPS}/rest/type/user/user`
      }
    },
    name: [{ value: state.mail }],
    mail: [{ value: state.mail }],
    pass: [{ value: state.password }]
  });
  const createNewUserBody = JSON.stringify([
    {
      requestId: getCSRFTokenReqId,
      uri: "/rest/session/token?_format=hal+json",
      action: "view"
    },
    {
      requestId: registerUserReqId,
      uri: REGISTER_USER_URI,
      body: userBody,
      headers: {
        "Content-Type": "application/hal+json",
        Accept: "application/hal+json"
      },
      action: "create"
    },
    {
      requestId: getOauthTokenReqId,
      uri: OAUTH_TOKEN_URI,
      body: JSON.stringify({
        grant_type: "password",
        client_id: "c165476e-a3cf-4c25-8906-f23062f73ad8",
        client_secret: "admin",
        // scope: "", --> lo quitamos porque no servía con refresh
        username: `${state.mail}`,
        password: `${state.password}`
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      waitFor: [registerUserReqId],
      action: "create"
    }
  ]);
  let userAlreadyExistsError = false;
  try {
    createUserRes = await transferDataWithSubrequestsAxios(
      SUBREQUESTS_URL,
      createNewUserBody
    );
    // Si la request contiene 422 (unprocessable entity) quiere decir que ya se tiene un registro para ese usuario
    userAlreadyExistsError = createUserRes.data[
      registerUserReqId
    ].headers.status.includes(422);
    if (userAlreadyExistsError) throw new Error();
    // Catch errors others than user is already registered

    Object.entries(createUserRes.data).forEach(item => {
      if (item[0].includes(getCSRFTokenReqId))
        csrfToken = createUserRes.data[getCSRFTokenReqId].body;
      if (item[0].includes(registerUserReqId))
        newUser = JSON.parse(createUserRes.data[registerUserReqId].body);
      if (item[0].includes(getOauthTokenReqId))
        oAuthToken = JSON.parse(createUserRes.data[getOauthTokenReqId].body);
      // si algunas de las responses contienen 400, crear un error genérico
      const otherErrors = item[1].headers.status.find(num =>
        num.toString().startsWith("4")
      );
      if (otherErrors) throw new Error();
    });

    // handle error cuando ya se tenía ese correo registrado
    // console.log("csrf Token", csrfToken);
    // console.log("newUser", newUser);
    // console.log("oauth Token", oAuthToken);
    // console.log("uuid", uuid);
    uuid = newUser.uuid[0].value;
  } catch (err) {
    if (userAlreadyExistsError) {
      // console.error("El usuario que se intenta registrar ya existe", err);
      return Error(422);
    } else {
      // console.error(
      //   "Hubo un error al momento de registrar un nuevo usuario",
      //   err
      // );
      return Error(400);
    }
  }

  // create new user entity that will be related to this one
  try {
    const dataSend = transformUserDataIntoSendableFormat(state, uuid);
    await dispatch(
      postOrPatchUserOrDoggiesData(oAuthToken, dataSend, "postUser", uuid)
    );
    dispatch({
      type: SET_TOKEN,
      payload: addInfoToTokenAndMakeItPersist(oAuthToken)
    });
  } catch {
    return Error(400);
  }
};

const getCSRFToken = () =>
  new Promise(resolve => {
    axios({
      method: "get",
      url: CSRF_URL
    }).then(res => resolve(res.data));
  });

const transformUserDataIntoSendableFormat = (userData, uuid) => {
  let sendableData = {
    data: {
      type: "node--profile",
      id: uuid,
      attributes: {
        title: `${userData.name} ${userData.lastName}`,
        name: userData.name,
        surname: userData.lastName,
        telephone: userData.telephone
      },
      relationships: {
        node_type: {
          data: {
            type: "contentTypes",
            id: "40308a59-b43e-478f-bf9c-2b8c6d9bb501"
          }
        },
        image: {
          data: {
            type: "files",
            id: "e7af9691-c20e-4257-9628-b52840805bda"
          }
        }
      }
    }
  };
  // if there is an image in userData, include it
  sendableData.data.relationships = userData.image
    ? {
        ...sendableData.data.relationships,
        image: {
          data: {
            type: "files",
            id: "e7af9691-c20e-4257-9628-b52840805bda"
          }
        }
      }
    : sendableData.data.relationships;
  return sendableData;
};

// transforma la info del doggy que se recibe desde el back para que tenga la estructura que usaremos en front
const doggyTransform = ({
  id,
  drupal_internal__nid,
  title,
  birthday,
  external_deworm,
  gender,
  internal_deworm,
  neutered,
  vet_name,
  vet_telephone_number,
  weight,
  image,
  size,
  breed,
  last_known_heat,
  allergies,
  cantidad_alimento,
  frecuencia_alimento,
  verified
}) => ({
  id,
  drupalId: drupal_internal__nid,
  nombre: title,
  nacimiento: birthday,
  desparasitacionExterna: external_deworm,
  desparasitacionInterna: internal_deworm,
  sexo: gender === "female" ? "hembra" : "macho",
  size,
  esterilizado: neutered,
  verificado: verified,
  veterinarioNombre: vet_name,
  veterinarioTelefono: vet_telephone_number,
  peso: weight,
  image: `${ROOT}${image.uri.url}`,
  raza: breed,
  fechaCelo: last_known_heat,
  alergias: allergies,
  alimentosFrecuencia: frecuencia_alimento,
  alimentosCantidad: cantidad_alimento,
  imageFile: image
});

// transforma la info del usuario (auth) que se recibe desde el back para que tenga la estructura que usaremos en front
const userTransform = ({ mail, currentPassword, newPassword }) => {
  let dataSend = {
    name: [
      {
        value: mail
      }
    ],
    mail: [
      {
        value: mail
      }
    ],
    pass: [
      {
        existing: currentPassword
      }
    ]
  };
  if (newPassword !== "") {
    dataSend.pass[0] = { ...dataSend.pass[0], value: newPassword };
  }

  return dataSend;
};

// transforma la info del usuario (profile) para que tenga el formato que se requiere en redux
const profileTransform = ({ id, name, surname, telephone, image, uid }) => ({
  id,
  name,
  lastName: surname,
  telephone,
  image: image.uri && `${ROOT}${image.uri.url}`,
  authUserName: uid.mail,
  mail: uid.mail
});
