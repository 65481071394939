import { SET_GALLERY_LOAD_STATUS } from "../actions/actionTypes";

const initialState = { loadingGalleryData: true };

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_GALLERY_LOAD_STATUS:
      return { ...state, loadingGalleryData: action.payload };
    default:
      return state;
  }
};
