import { combineReducers } from "redux";
import programarCita from "./programarCita";
import user from "./user";
import staticContent from "./staticContent";
import loadingStatus from "./loadingStatus";
import servicesLoadingStatus from "./servicesLoadingStatus.js";
import galleryLoadingStatus from "./galleryLoadingStatus.js";
import infoProgramarCita from "./infoProgramarCita";

export default combineReducers({
  programarCita,
  user,
  staticContent,
  loadingStatus,
  servicesLoadingStatus,
  galleryLoadingStatus, 
  infoProgramarCita
});
