export const ROOT = `https://development.holidaypets.mx`;
export const ROOT_NOHTTPS = `http://development.holidaypets.mx`;
// export const ROOT = "http://192.168.1.89:32787";
export const API_ROOT = `${ROOT}/api`;

export const HOMEPAGE_URL =
  `${API_ROOT}/node/homepage?` +
  `fields[paragraph--paso]=title,body,image` +
  `&fields[images]=imageFile` +
  `&fields[files]=uri` +
  `&fields[node--homepage]=terms_conditions,printable_form,daycare_available,pickup_procedure_info,hotel_available,school_period_length,school_with_daycare_available,school_with_hotel_available,social_network_link,` +
  `spa_available,title,button_text,direccion,doggy_sizes_breakpoints,email,in,in2,intro_,sections,secciones,requisitos,steps,tagline,telefono,image,` +
  `logo,fav,doggy_bus_prices_by_zipcode,zipcodes_for_daycare,zipcodes_for_daycare_school,zipcodes_for_hotel,` +
  `zipcodes_for_hotel_school,zipcodes_for_spa` +
  `&include=requisitos,steps,steps.image,secciones,secciones.image,image,logo,fav,doggy_bus_prices_by_zipcode,printable_form`;
// export const INFO_PROGRAMAR_CITA_URL = `${API_ROOT}/node/bookable_service?fields%5Bnode--bookable_service%5D=title,field_price,covera`;
export const GALERIA_URL =
  `${API_ROOT}/node/homepage?` +
  `&fields[node--homepage]=galeria` +
  `&include=galeria.imageFile`;
export const TESTIMONIOS_URL =
  `${API_ROOT}/node/testimonio?` +
  `include=image` +
  `&fields[node--testimonio]=title,body,image`;
export const SERVICIOS_URL =
  `${API_ROOT}/node/service?` +
  `fields[node--service]=title,a,ac,amenidades,ben,body,cobertura,direccion_del_servicio,features,grados_si_es_escuela_,horar,horario,image,modalidad_guarderia,modalidad_hotel,precio_por_sesion,recomendaciones,sesiones_si_es_spa_,service_tagline,warning` +
  `&include=a,image,amenidades,amenidades.image,horar,precio_por_sesion,sesiones_si_es_spa_,grados_si_es_escuela_.con_guarderia,grados_si_es_escuela_.con_hotel`;
// para cuando se quieren mandar varias requests en una sola, se usa subrequests:

export const SUBREQUESTS_URL = `${ROOT}/subrequests`;
export const CSRF_URI = `/rest/session/token`;
export const CSRF_URL = `${ROOT}${CSRF_URI}`;

export const DOGGY_IMAGE_UPLOAD_URI = `/file/upload/node/doggy/image?_format=json`;
export const PROFILE_IMAGE_UPLOAD_URI = `/file/upload/node/profile/image?_format=json`;
export const USER_URI = "/user";
export const REGISTER_USER_URI = `${USER_URI}/register`;

export const OAUTH_TOKEN_URI = `/oauth/token`;
export const USERS_URI = `/api/users`;
export const DOGGY_URI = `/api/node/doggy`;
export const PROFILE_URI = `/api/node/profile`;

export const DOGGY_IMAGE_UPLOAD_URL = `${ROOT}${DOGGY_IMAGE_UPLOAD_URI}`;
export const PROFILE_IMAGE_UPLOAD_URL = `${ROOT}${PROFILE_IMAGE_UPLOAD_URI}`;

export const WEBFORM_SUBMIT_URI = `/webform_rest/submit`;
export const WEBFORM_SUBMIT_URL = `${ROOT}${WEBFORM_SUBMIT_URI}`;

export const MAX_FILE_UPLOAD_SIZE = 2000000;

export const REQUEST_PASSWORD_RESET_URL = `${ROOT}/user/lost-password`;
export const PASSWORD_RESET_URL = `${ROOT}/user/lost-password-reset`;

export const WEBFORM_SUBMISSIONS_IDS_URI = `/api/webform_submission/order`; //para obtener los ids de las webforms que ha submitted un usuario
export const WEBFORM_SUBMISSIONS_URI = `/webform_rest/order/submission`; // uri base sobre la cual se deberán incluir los ids de las submitted webforms

// define a window width breaking point for switching between hamburger and full navbar
export const SCREEN_WIDTH_BREAKPOINT_FOR_NAVBAR = 700;

export const DEFAULT_DOGGY_IMAGE_ID = "b4373f9b-fa40-4a43-a0e3-4ec97a149230";
export const DEFAULT_DOGGY_IMAGE_URL = `${ROOT}/sites/default/files/2020-04/default_doggy.png`;

export const customerCredentials = {
  base: ROOT,
  oauth: {
    grant_type: "password",
    client_id: "c165476e-a3cf-4c25-8906-f23062f73ad8", // YOUR CLIENT ID
    client_secret: "admin", // YOUR CLIENT SECRET
    scope: "" // USER ROLE
  }
};
