//

import {
  ADD_DOGGY_TO_CITA,
  REMOVE_DOGGY_FROM_CITA,
  SET_FECHA_INICIO_CITA,
  SET_FECHA_FIN_CITA,
  SET_DIRECCION,
  SET_NIVEL_ESCUELA,
  SET_SESION_SPA,
  SET_SERVICIO_SELECCIONADO,
  ADD_SERVICIO_TO_ORDEN,
  RESET_ORDEN,
  RESET_CITA,
  SET_CODIGO_POSTAL,
  SET_TRANSPORTATION_UNIT_PRICE,
  SET_DIRECCION_SERVICIO,
  SET_SENDING_ORDER,
  SET_INNER_NUMBER,
  SET_MENSAJE_ADICIONAL,
  SET_SERVICIO_ADICIONAL_SPA,
  REMOVE_SERVICIO_ADICIONAL_SPA
} from "../actions/actionTypes";

const initialState = {
  doggiesServicio: [],
  servicioSeleccionado: "",
  fechaInicio: "",
  fechaFin: "",
  direccionCliente: "",
  direccionServicio: "", // se refiere a la dirección donde se hará el servicio (guardería, hotel o spa)
  nivelEscuela: "",
  tipoSesionSpa: "",
  codigoPostal: "",
  orden: [],
  transportationUnitPrice: 0,
  sendingOrder: false,
  innerNumber: "",
  mensajeAdicional: "",
  servicioSpaSiNoEsSpa: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_DOGGY_TO_CITA:
      return {
        ...state,
        doggiesServicio: [
          ...new Set([...state.doggiesServicio, action.payload])
        ]
      };
    case REMOVE_DOGGY_FROM_CITA:
      return {
        ...state,
        doggiesServicio: state.doggiesServicio.filter(
          item => item !== action.payload
        )
      };
    case SET_FECHA_INICIO_CITA:
      return {
        ...state,
        fechaInicio: action.payload
      };
    case SET_MENSAJE_ADICIONAL:
      return { ...state, mensajeAdicional: action.payload };
    case SET_FECHA_FIN_CITA:
      return {
        ...state,
        fechaFin: action.payload
      };
    case SET_SERVICIO_SELECCIONADO:
      return {
        ...state,
        servicioSeleccionado: action.payload
      };
    case SET_DIRECCION:
      return {
        ...state,
        direccionCliente: action.payload
      };
    case SET_INNER_NUMBER:
      return {
        ...state,
        innerNumber: action.payload
      };
    case SET_DIRECCION_SERVICIO:
      return {
        ...state,
        direccionServicio: action.payload
      };
    case SET_CODIGO_POSTAL:
      return {
        ...state,
        codigoPostal: action.payload
      };
    case SET_SESION_SPA:
      return {
        ...state,
        tipoSesionSpa: action.payload
      };
    case SET_NIVEL_ESCUELA:
      return {
        ...state,
        nivelEscuela: action.payload
      };
    case ADD_SERVICIO_TO_ORDEN:
      return {
        ...state,
        orden: [...state.orden, action.payload]
      };
    case SET_TRANSPORTATION_UNIT_PRICE:
      return { ...state, transportationUnitPrice: action.payload };
    case SET_SENDING_ORDER:
      return { ...state, sendingOrder: action.payload };
    case SET_SERVICIO_ADICIONAL_SPA:
      return { ...state, servicioSpaSiNoEsSpa: action.payload };
    case REMOVE_SERVICIO_ADICIONAL_SPA:
      return {
        ...state,
        servicioSpaSiNoEsSpa: initialState.servicioSpaSiNoEsSpa
      };
    case RESET_ORDEN:
      return {
        ...state,
        orden: []
      };
    case RESET_CITA:
      return { ...initialState };
    default:
      return state;
  }
};
