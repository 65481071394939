import axios from "axios";
//TODO: hay que encargarnos de que se esté mandando a redux la respuesta, al perfil del usuario
export const transferDataWithSubrequests = (url, body) =>
  new Promise(resolve => {
    // console.log(
    //   "body inside transferDataWithSubrequests (parsed)",
    //   JSON.parse(body)
    // );
    // console.log("body inside transferDataWithSubrequests ", body);
    fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json"
      },
      body
    })
      .then(res => {
        // console.log(res);
        return res.json();
      })
      .then(res => {
        // console.log("response from transfer data with subrequests", res);
        resolve(res);
      })
      .catch(err => {
        console.log("error al momento de fetch subrequests", err);
      });
  });

export const transferDataWithSubrequestsAxios = (url, body) =>
  new Promise(resolve => {
    axios({
      url: url,
      headers: {
        Accept: "application/json"
      },
      method: "post",
      data: body
    })
      .then(res => {
        // console.log(res);
        resolve(res);
      })
      .catch(err => {
        console.log("error al momento de fetch subrequests", err);
      });
  });
