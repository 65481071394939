import React, { useEffect, lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import theme from "./theme";
import { MuiThemeProvider } from "@material-ui/core";
import ScrollToTop from "./auxiliary-components/ScrollToTop.component";
import store from "./store";
import { connect } from "react-redux";
import LoadingDoggy from "./components/LoadingDoggy.component";
import {
  fetchHomePageText,
  fetchServiciosText,
  setInfoProgramarCita
} from "./redux/actions";
import { SET_INITIAL_LOAD_STATUS } from "./redux/actions/actionTypes";
import PrivateRoute from "./components/frontend-routes/PrivateRoute.component";

const ScreenHome = lazy(() => import("./pages/ScreenHome.component"));
const ScreenLogin = lazy(() => import("./pages/ScreenLogin.component"));
const ScreenProfile = lazy(() => import("./pages/ScreenProfile.component"));
const ScreenProgramarCita = lazy(() =>
  import("./pages/ScreenProgramarCita.component")
);
const ScreenRecuperaPassword = lazy(() =>
  import("./pages/ScreenRecuperaPassword.component")
);
const ScreenRegistro = lazy(() => import("./pages/ScreenRegistro.component"));
const ScreenServicioEscuela = lazy(() =>
  import("./pages/ScreenServicioEscuela.component")
);
const ScreenServicioGuarderia = lazy(() =>
  import("./pages/ScreenServicioGuarderia.component")
);
const ScreenServicioHotel = lazy(() =>
  import("./pages/ScreenServicioHotel.component")
);
const ScreenServicioSpa = lazy(() =>
  import("./pages/ScreenServicioSpa.component")
);
const ScreenContacto = lazy(() => import("./pages/ScreenContacto.component"));
const ScreenTerminosYCondiciones = lazy(() =>
  import("./pages/ScreenTerminosYCondiciones.component")
);
const ScreenResetPassword = lazy(() =>
  import("./pages/ScreenResetPassword.component")
);

const styles = {
  "@global": {
    html: {
      margin: "0"
    },
    body: {
      margin: "0"
    },
    "#root": {
      overflowX: "hidden",
      position: "relative"
    },
    ".MuiPaper-rounded": {
      borderRadius: 8
    }
  }
};

const App = props => {
  useEffect(() => {
    const getInitialData = async () => {
      Promise.all([
        store.dispatch(fetchHomePageText()),
      ]).then(() => {
        store.dispatch({ type: SET_INITIAL_LOAD_STATUS, payload: false });
      });
    };
    getInitialData();
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      {props.loadingInitialData ? (
        <div>
          <LoadingDoggy />
        </div>
      ) : (
        <Suspense fallback={<LoadingDoggy />}>
          <BrowserRouter>
            <ScrollToTop />
            <Switch>
              <Route path="/" exact={true} component={ScreenHome} />
              <Route path="/login" component={ScreenLogin} />

              <PrivateRoute path="/profile" component={ScreenProfile} />
              <PrivateRoute
                path="/programarCita"
                component={ScreenProgramarCita}
              />

              <Route
                path="/recuperaPassword"
                component={ScreenRecuperaPassword}
              />
              <Route path="/registro" component={ScreenRegistro} />
              <Route path="/contacto" component={ScreenContacto} />
              <Route
                path="/terminosYCondiciones"
                component={ScreenTerminosYCondiciones}
              />

              <Route
                path="/servicioEscuela"
                component={ScreenServicioEscuela}
              />
              <Route
                path="/servicioGuarderia"
                component={ScreenServicioGuarderia}
              />
              <Route path="/servicioHotel" component={ScreenServicioHotel} />
              <Route path="/servicioSpa" component={ScreenServicioSpa} />
              <Route path="/pwr" component={ScreenResetPassword} />
              <Redirect to="/" />
            </Switch>
          </BrowserRouter>
        </Suspense>
      )}
    </MuiThemeProvider>
  );
};

const mapStateToProps = state => {
  return {
    loadingInitialData: state.loadingStatus.loadingInitialData
  };
};
export default withStyles(styles)(connect(mapStateToProps)(App));
