import { deleteUnnecessaryInfoFromResponse } from "./cleanResponse";
import { ROOT } from "../../../config";

// f_key: foreign key
export const serviciosResponseToTextGraph = res => {
  // clean response from unnecessary data
  const cleanRes = deleteUnnecessaryInfoFromResponse(res.data, [
    "jsonapi",
    "links"
  ]);
  // create textgraph object (with some empty fields)
  let tg = createTextGraph();
  // create auxiliary object that will be used to populate the textgraph object
  const aux = createAndPopulateAuxObj(cleanRes, tg);
  // populate the textgraph object
  populateTextGraphObj(aux, tg);
  return tg;
};

// AUXILIARY FUNCTIONS
const createTextGraph = () => {
  /** returns un objeto (textGraph) vacío (con foreign key, que se refiere al id del servicio en el back) y un id (interno para react)
   * Este objecto se usará como base para irse llenando con la información requerida por cada una de las páginas.
   * Al final se manda a redux para conformar el staticContent*/
  let textGraph = {
    servicioEscuela: {
      f_key: "0cf9eca8-99d9-47cd-8fda-0b5478fc5172",
      id: "escuela"
    },
    servicioGuarderia: {
      f_key: "9011ff9e-13f6-4a72-ac67-fdc541cb0be7",
      id: "guarderia"
    },
    servicioHotel: {
      f_key: "3e433b8b-10de-4690-9623-66467f13f59b",
      id: "hotel"
    },
    servicioSpa: { f_key: "d0d6a7ca-01ca-48bb-8086-e985b7431ad6", id: "spa" }
  };

  const baseObj = {
    includes: []
  };
  for (let servicio in textGraph) {
    textGraph[servicio] = { ...textGraph[servicio], ...baseObj };
  }

  return textGraph;
};

const createAndPopulateAuxObj = (res, tg) => {
  // este método crea un objeto auxiliar que servirá después para ir poblando el textgraph.  Primero se agregan los atributos del
  // objecto que ser recibe de response y después se agrega la info que viene en las relationships
  // console.log(res);
  let aux = {};
  for (let servicio in tg) {
    // INCLUYE VARIABLES QUE SON DIRECTAMENTE ATRIBUTOS DE LA RESPONSE
    aux = {
      ...aux,
      [servicio]: res.data
        .filter(item => item.type === "node--service")
        .find(item => item.id === tg[servicio].f_key).attributes
    };

    // IMAGEN DE CARD (AHORA MISMO NO SE AGREGA A LA CARD. ESO DE HACE DESPUÉS)
    const imageID = res.data
      .filter(item => item.type === "node--service")
      .find(item => item.id === tg[servicio].f_key).relationships.image.data.id;
    const cardImageUrl = res.included.find(item => item.id === imageID).links.thumbnail.href;

    // PRECIO DE CARD (AHORA MISMO NO SE AGREGA A LA CARD. ESO DE HACE DESPUÉS)
    const precioID = res.data.find(item => item.id === tg[servicio].f_key)
      .relationships.precio_por_sesion.data.id;
    const opt1 = res.included.find(item => item.id === precioID).attributes
      .title;
    const precio =
      res.included.find(item => item.id === precioID).attributes.precio !==
        null &&
      parseInt(
        res.included.find(item => item.id === precioID).attributes.precio.number
      );
    const opt2 =
      res.included.find(item => item.id === precioID).attributes.tiempo !==
        null &&
      res.included.find(item => item.id === precioID).attributes.tiempo
        .processed;

    // AMENIDADES
    const amenidadesIDs = res.data
      .filter(item => item.type === "node--service")
      .find(item => item.id === tg[servicio].f_key)
      .relationships.amenidades.data.map(item => item.id);
    const amenidades = amenidadesIDs.map(id => {
      // agrega title
      const title = res.included.find(item => item.id === id).attributes.name;
      // agrega imageFile
      const imageFileID = res.included.find(item => item.id === id)
        .relationships.image.data.id;
      const imageFile = `${ROOT}${
        res.included.find(item => item.id === imageFileID).attributes.uri.url
      }`;
      return { title, imageFile };
    });

    // ALIMENTACIÓN
    const alimentacionID = res.data
      .filter(item => item.type === "node--service")
      .find(item => item.id === tg[servicio].f_key).relationships.a.data.id;
    const alimentacion =
      res.included.find(item => item.id === alimentacionID).attributes
        .paragraph_body &&
      res.included.find(item => item.id === alimentacionID).attributes;

    // CHIPS ASOCIADAS A ALIMENTACIÓN
    const alimentacion_red_chips = res.included
      .find(item => item.id === alimentacionID)
      .attributes.paragraph_warnings.map(item => item.processed);
    const alimentacion_green_chips =
      res.included.find(item => item.id === alimentacionID).attributes
        .paragraph_features &&
      res.included
        .find(item => item.id === alimentacionID)
        .attributes.paragraph_features.map(item => item.processed);

    // HORARIO DE TRANSPORTE
    const horarioID = res.data.find(item => item.id === tg[servicio].f_key)
      .relationships.horar.data.id;
    const horarioTransporte = () => {
      return (
        res.included.find(item => item.id === horarioID) &&
        res.included.find(item => item.id === horarioID).attributes
          .paragraph_body.processed
      );
    };

    // CHIPS DE HORARIO
    const horarioRedChips =
      res.included.find(item => item.id === horarioID) &&
      res.included
        .find(item => item.id === horarioID)
        .attributes.paragraph_warnings.map(it => ({
          label: it.processed.replace("[cajon_cobertura]", ""),
          color: "primary",
          textColor: "primary",
          backgroundColor: "primary",
          withOpacity: true,
          ...(it.processed.includes("[cajon_cobertura]") && {
            withCoberturaDrawer: true
          })
        }));
    const horarioGreenChips =
      res.included.find(item => item.id === horarioID) &&
      res.included
        .find(item => item.id === horarioID)
        .attributes.paragraph_features.map(it => ({
          label: it.processed.replace("[cajon_cobertura]", ""),
          color: "secondary",
          textColor: "secondary",
          backgroundColor: "secondary",
          withOpacity: true,
          ...(it.processed.includes("[cajon_cobertura]") && {
            withCoberturaDrawer: true
          })
        }));

    // SESIONES DE SPA
    const sesionesSpa = () => {
      const sesionesIDs = res.data
        .find(item => item.id === tg[servicio].f_key)
        .relationships.sesiones_si_es_spa_.data.map(it => it.id);
      return sesionesIDs.map(ses_id => {
        const attrs = res.included.find(it => it.id === ses_id).attributes;
        return {
          title: attrs.title,
          id: ses_id,
          precios: [
            {
              size: "s",
              opt2: "perro chico",
              precio: parseInt(attrs.precio_para_doggy_chico.number)
            },
            {
              size: "m",
              opt2: "perro mediano",
              precio: parseInt(attrs.precio_para_doggy_mediano.number)
            },
            {
              size: "l",
              opt2: "perro grande",
              precio: parseInt(attrs.precio_para_doggy_grande.number)
            },
            {
              size: "xl",
              opt2: "perro extra grande",
              precio: parseInt(attrs.precio_para_doggy_extra_grande.number)
            }
          ],
          serviciosList: attrs.requisito.map(serv => serv.processed)
        };
      });
    };

    // MODALIDADES DE GUARDERÍA Y HOTEL (QUE SÓLO SE USARÁN PARA EL SERVICIO DE ESCUELA)
    const modalidadGuarderia =
      !!res.data.find(item => item.id === tg[servicio].f_key).attributes
        .modalidad_guarderia &&
      res.data.find(item => item.id === tg[servicio].f_key).attributes
        .modalidad_guarderia.processed;
    const modalidadHotel =
      !!res.data.find(item => item.id === tg[servicio].f_key).attributes
        .modalidad_hotel &&
      res.data.find(item => item.id === tg[servicio].f_key).attributes
        .modalidad_hotel.processed;
    // console.log(modalidadGuarderia);

    // DESCRIPCIÓN DE GRADOS (SÓLO SE USARÁN PARA EL SERVICIO DE ESCUELA)
    const descripcionGrados = () => {
      const gradosIDs = res.data
        .find(item => item.id === tg[servicio].f_key)
        .relationships.grados_si_es_escuela_.data.map(it => it.id);
      return gradosIDs.map(id => {
        const attrs = res.included.find(it => it.id === id).attributes;

        const rels = res.included.find(it => it.id === id).relationships;
        const guarderiaID = rels.con_guarderia.data.id;
        const hotelID = rels.con_hotel.data.id;
        const precioConGuarderia = parseInt(
          res.included.find(it => it.id === guarderiaID).attributes.precio
            .number
        );
        const tiempoGuarderia = res.included.find(it => it.id === guarderiaID)
          .attributes.tiempo.processed;
        const precioHotel = parseInt(
          res.included.find(it => it.id === hotelID).attributes.precio.number
        );
        const tiempoHotel = res.included.find(it => it.id === guarderiaID)
          .attributes.tiempo.processed;

        const gradoId = attrs.title
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(" ", "");

        return {
          gradoId,
          title: attrs.title,
          subtitle: attrs.paragraph_body.processed,
          contentList: attrs.requisito.map(it => it.processed),
          options: [
            {
              gradoId,
              id: hotelID,
              type: "conHotel",
              opt1: "Con Hotel",
              precio: precioHotel,
              opt2: tiempoHotel
            },
            {
              gradoId,
              id: guarderiaID,
              type: "conGuarderia",
              opt1: "Con Guardería",
              precio: precioConGuarderia,
              opt2: tiempoGuarderia
            }
          ]
        };
      });
    };

    // AHORA SÍ SE AGREGAN
    aux[servicio] = {
      ...aux[servicio],
      imageSource: cardImageUrl,
      precio: { opt1, precio, opt2 },
      amenidades: amenidades && amenidades,
      alimentacion,
      alimentacion_red_chips,
      alimentacion_green_chips,
      horarioTransporte: horarioTransporte(),
      horarioGreenChips,
      horarioRedChips,
      sesionesSpa: sesionesSpa(),
      modalidadGuarderia,
      modalidadHotel,
      descripcionGrados: descripcionGrados()
    };
  }
  return aux;
};

const populateTextGraphObj = (aux, tg) => {
  // POBLAMIENTO DEL TEXTGRAPH | f(aux) --> tg -- |
  for (let servicio in aux) {
    // title --> card.title
    // service_tagline --> card.subtitle
    // body --> card.body
    // imageSource --> card.imageSource
    tg[servicio].card = {
      title: aux[servicio].title,
      subtitle: aux[servicio].service_tagline.processed,
      body: aux[servicio].body.processed,
      imageSource: aux[servicio].imageSource,
      precio: aux[servicio].precio
    };
    // agrega precio a card

    // amenidades --> amenidades
    tg[servicio] = {
      ...tg[servicio],
      amenidades: aux[servicio].amenidades
    };

    // features --> includes.label (color secundario o verde) (chips)
    aux[servicio].features.forEach(it => {
      tg[servicio].includes = [
        ...tg[servicio].includes,
        {
          label: it.processed.replace("[cajon_cobertura]", ""),
          color: "secondary",
          textColor: "secondary",
          backgroundColor: "secondary",
          withOpacity: true,
          ...(it.processed.includes("[cajon_cobertura]") && {
            withCoberturaDrawer: true
          })
        }
      ];
    });

    // warning --> includes.label (color primario o rojo) (chips)
    aux[servicio].warning.forEach(it => {
      tg[servicio].includes = [
        ...tg[servicio].includes,
        {
          label: it.processed.replace("[cajon_cobertura]", ""),
          color: "primary",
          textColor: "primary",
          backgroundColor: "primary",
          withOpacity: true,
          ...(it.processed.includes("[cajon_cobertura]") && {
            withCoberturaDrawer: true
          })
        }
      ];
    });

    tg[servicio] = {
      ...tg[servicio],
      horarioActividades:
        aux[servicio].horario && aux[servicio].horario.processed,
      actividadesDiarias: aux[servicio].ac && aux[servicio].ac.processed,
      direccion:
        aux[servicio].direccion_del_servicio &&
        aux[servicio].direccion_del_servicio.processed,
      alimentacion:
        aux[servicio].alimentacion !== null &&
        aux[servicio].alimentacion.paragraph_body.processed,
      alimentacionRedChips: aux[servicio].alimentacion_red_chips.map(it => ({
        label: it,
        color: "primary",
        textColor: "primary",
        backgroundColor: "primary",
        withOpacity: true
      })),
      alimentacionGreenChips: aux[servicio].alimentacion_green_chips.map(
        it => ({
          label: it,
          color: "secondary",
          textColor: "secondary",
          backgroundColor: "secondary",
          withOpacity: true
        })
      ),
      recomendaciones: aux[servicio].recomendaciones.processed,
      horarioTransporte: aux[servicio].horarioTransporte,
      horarioRedChips: aux[servicio].horarioRedChips,
      horarioGreenChips: aux[servicio].horarioGreenChips,
      beneficios: aux[servicio].ben !== null && aux[servicio].ben.processed
    };

    // AGREGA ATRIBUTOS ESPECÍFICOS DE UN SERVICIO
    if (servicio === "servicioSpa")
      tg[servicio] = {
        ...tg[servicio],
        sesionesSpa: aux[servicio].sesionesSpa
      };

    if (servicio === "servicioEscuela")
      tg[servicio] = {
        ...tg[servicio],
        modalidadGuarderia: {
          type: "guarderia",
          image: "",
          title: "Modalidad guardería",
          description: aux[servicio].modalidadGuarderia,
          beneficiosText: "Beneficios de la guardería"
        },
        modalidadHotel: {
          type: "hotel",
          image: "",
          title: "Modalidad hotel",
          description: aux[servicio].modalidadHotel,
          beneficiosText: "Beneficios del hotel"
        },
        descripcionGrados: aux[servicio].descripcionGrados
      };

    if (servicio === "servicioHotel") {
      tg[servicio] = {
        ...tg[servicio]
      };
    }

    if (servicio === "servicioGuarderia") {
      tg[servicio] = {
        ...tg[servicio]
      };
    }
  }

  return tg;
};
