import { SET_INITIAL_LOAD_STATUS } from "../actions/actionTypes";

const initialState = { loadingInitialData: true };

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_LOAD_STATUS:
      return { ...state, loadingInitialData: action.payload };
    default:
      return state;
  }
};
