import { SET_INFO_PROGRAMAR_CITA } from "../actions/actionTypes";

// aquí se maneja todo lo relativo a la información para programar una cita (tipos de servicio, precios, etc.)
const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INFO_PROGRAMAR_CITA:
      return action.payload;
    default:
      return state;
  }
};
