import {
  SET_TOKEN,
  SET_USER_INFO,
  SET_USER_DOGGIES,
  ADD_DOGGY_TO_DOGGIES,
  EDIT_DOGGY_FROM_DOGGIES,
  REMOVE_DOGGY_FROM_DOGGIES,
  DELETE_TOKEN,
  DELETE_USER_INFO,
  DELETE_USER_DOGGIES,
  SET_USER_AUTH,
  SET_HISTORIAL_ORDENES,
  DELETE_HISTORIAL_ORDENES
} from "../actions/actionTypes";

const initialState = {
  userInfo: {},
  doggies: [],
  token:
    localStorage.getItem("token") !== null
      ? JSON.parse(localStorage.getItem("token"))
      : null,

  auth: {},
  historialOrdenes: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return { ...state, token: action.payload };
    case SET_USER_INFO:
      return { ...state, userInfo: action.payload };
    case SET_USER_DOGGIES:
      return { ...state, doggies: action.payload };
    case SET_HISTORIAL_ORDENES:
      return { ...state, historialOrdenes: action.payload };
    case SET_USER_AUTH:
      return { ...state, userAuth: action.payload };
    case ADD_DOGGY_TO_DOGGIES:
      return { ...state, doggies: [...state.doggies, action.payload] };
    case EDIT_DOGGY_FROM_DOGGIES:
      return {
        ...state,
        doggies: state.doggies.map(doggy =>
          doggy.id === action.payload.id ? action.payload : doggy
        )
      };
    case REMOVE_DOGGY_FROM_DOGGIES:
      // a diferencia de los demás casos (donde se proporciona el doggy, aquí sólo se requiere del ID)
      return {
        ...state,
        doggies: state.doggies.filter(doggy => doggy.id !== action.payload)
      };
    case DELETE_USER_INFO:
      return { ...state, userInfo: initialState.userInfo };
    case DELETE_USER_DOGGIES:
      return { ...state, doggies: initialState.doggies };
    case DELETE_HISTORIAL_ORDENES:
      return { ...state, historialOrdenes: initialState.historialOrdenes };
    case DELETE_TOKEN:
      return { ...state, token: null };

    default:
      return state;
  }
};
