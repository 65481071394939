export const serviciosContentToInfoProgramarCita = data => {
  //transforms serviciosContent to info programar cita (the output will be included in redux)
  // console.log(data);
  // hotel
  const hotel = {
    id: data.servicioHotel.id,
    type: data.servicioHotel.id,
    title: data.servicioHotel.card.title,
    disponible: false,
    precio: data.servicioHotel.card.precio.precio,
    image: data.servicioHotel.card.imageSource
  };
  // guarderia
  const guarderia = {
    id: data.servicioGuarderia.id,
    type: data.servicioGuarderia.id,
    title: data.servicioGuarderia.card.title,
    disponible: false,
    precio: data.servicioGuarderia.card.precio.precio,
    image: data.servicioGuarderia.card.imageSource
  };
  // spa
  // NOTA IMPORTANTE: se declaran identificadores internos para las sesiones de spa. Se deben proveer en este orden desde el back
  const subserviciosIds = ["sesionSense", "sesionHarmony", "sesionCore"];
  const spa = {
    id: data.servicioSpa.id,
    type: data.servicioSpa.id,
    title: data.servicioSpa.card.title,
    disponible: false,
    image: data.servicioSpa.card.imageSource,
    subServicios: data.servicioSpa.sesionesSpa.map((sesion, i) => ({
      label: sesion.title,
      id: subserviciosIds[i],
      precios: sesion.precios
    }))
  };
  // NOTA: escuelaConGuarderia y escuelaConHotel see hardcodean porque son casos particulares que no se proveen tal cual desde back
  // escuelaConGuarderia
  const escuelaConGuarderia = {
    id: "escuelaConGuarderia",
    type: "escuela",
    title: "Escuela",
    options: ["Con guardería"],
    disponible: false,
    duracion: data.homePage.data[0].attributes.school_period_length,
    image: data.servicioEscuela.card.imageSource,
    subServicios: data.servicioEscuela.descripcionGrados.map(grado => ({
      id: grado.gradoId,
      label: grado.title,
      precio: grado.options.find(opt => opt.type === "conGuarderia").precio
    }))
    // subServicios: data.servicio
  };
  // escuelaConHote
  const escuelaConHotel = {
    id: "escuelaConHotel",
    type: "escuela",
    title: "Escuela",
    options: ["Con hotel"],
    disponible: false,
    duracion: data.homePage.data[0].attributes.school_period_length,
    image: data.servicioEscuela.card.imageSource,
    subServicios: data.servicioEscuela.descripcionGrados.map(grado => ({
      id: grado.gradoId,
      label: grado.title,
      precio: grado.options.find(opt => opt.type === "conHotel").precio
    }))
  };

  // el transporte sólo se usa como base para rellenar el campo de transporte de la orden. Su precio se determina en funció del código postal del cliente.
  const transporte = { id: "transporte" };

  return [
    hotel,
    guarderia,
    spa,
    escuelaConGuarderia,
    escuelaConHotel,
    transporte
  ];
};
