import {
  SET_HOMEPAGE_CONTENT,
  SET_SERVICIOS_CONTENT,
  SET_GALERIA_CONTENT
} from "../actions/actionTypes";

const initialState = {
  homePage: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HOMEPAGE_CONTENT:
      return { ...state, homePage: action.payload };
    case SET_SERVICIOS_CONTENT:
      return { ...state, ...action.payload };
    case SET_GALERIA_CONTENT:
      return { ...state, galeria: action.payload };
    default:
      return state;
  }
};
