// CONTENT
export const SET_HOMEPAGE_CONTENT = "SET_HOMEPAGE_CONTENT";
export const SET_SERVICIOS_CONTENT = "SET_SERVICIOS_CONTENT";
export const SET_GALERIA_CONTENT = "SET_GALERIA_CONTENT";

// PROGRAMAR CITA
export const SET_INFO_PROGRAMAR_CITA = "SET_INFO_PROGRAMAR_CITA";

export const RESET_CITA = "RESET_CITA";
export const ADD_DOGGY_TO_CITA = "ADD_DOGGY_TO_CITA";
export const REMOVE_DOGGY_FROM_CITA = "REMOVE_DOGGY_FROM_CITA";
export const SET_FECHA_INICIO_CITA = "SET_FECHA_INICIO_CITA";
export const SET_FECHA_FIN_CITA = "SET_FECHA_FIN_CITA";
export const SET_INNER_NUMBER = "SET_INNER_NUMBER";
export const SET_TRANSPORTATION_UNIT_PRICE = "SET_TRANSPORTATION_UNIT_PRICE";
export const SET_DIRECCION = "SET_DIRECCION"; // para la dirección del cliente
export const SET_DIRECCION_SERVICIO = "SET_DIRECCION_SERCVICIO"; // para la dirección donde se efectuará el servicio (guardería, spa, hotel)
export const SET_CODIGO_POSTAL = "SET_CODIGO_POSTAL";
export const SET_NIVEL_ESCUELA = "SET_NIVEL_ESCUELA";
export const SET_SESION_SPA = "SET_SESION_SPA";
export const SET_SERVICIO_SELECCIONADO = "SET_SERVICIO_SELECCIONADO";
export const ADD_SERVICIO_TO_ORDEN = "ADD_SERVICIO_TO_ORDEN";
export const RESET_ORDEN = "RESET_ORDEN";
export const SET_SENDING_ORDER = "SET_SENDING_ORDER";
export const SET_MENSAJE_ADICIONAL = "SET_MENSAJE_ADICIONAL";
export const SET_SERVICIO_ADICIONAL_SPA = "SET_SERVICIO_ADICIONAL_SPA";
export const REMOVE_SERVICIO_ADICIONAL_SPA = "REMOVE_SERVICIO_ADICIONAL_SPA";

// LOADING STATUS
export const SET_INITIAL_LOAD_STATUS = "SET_INITIAL_LOAD_STATUS";
export const SET_SERVICES_LOAD_STATUS = "SET_SERVICES_LOAD_STATUS";
export const SET_GALLERY_LOAD_STATUS = "SET_GALLERY_LOAD_STATUS";

// user
export const SET_TOKEN = "SET_TOKEN";

export const SET_USER_AUTH = "SET_USER_AUTH";
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_USER_DOGGIES = "SET_USER_DOGGIES";
export const ADD_DOGGY_TO_DOGGIES = "ADD_DOGGY_TO_DOGGIES";
export const EDIT_DOGGY_FROM_DOGGIES = "EDIT_DOGGY_FROM_DOGGIES";
export const REMOVE_DOGGY_FROM_DOGGIES = "REMOVE_DOGGY_FROM_DOGGIES";
export const REGISTER_USER = "REGISTER_USER";
export const SET_HISTORIAL_ORDENES = "SET_HISTORIAL_ORDENES";

export const DELETE_TOKEN = "DELETE_TOKEN";
export const DELETE_USER_INFO = "DELETE_USER_INFO";
export const DELETE_USER_DOGGIES = "DELETE_USER_DOGGIES";
export const DELETE_HISTORIAL_ORDENES = "DELETE_HISTORIAL_ORDENES";
