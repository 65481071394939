import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";
import { ROOT } from "../../config";
import {
  refreshAndSetOauthToken,
  fetchAndSetUserAndDoggiesData
} from "../../redux/actions";
import LoadingDoggy from "../LoadingDoggy.component";

const PrivateRoute = props => {
  /**
   * @params:
   *    Component: componente que se mostrará si cumple con los requerimentos
   *
   * @desc:
   *    Checks credentials:
   *        if token && token expired: try getting token
   *        if token && token valid: continue
   *    Checks if user info is complete (e.g., so that there doesn't appear to be no doggies whilst waiting for them)
   */
  const { component: Component, user, dispatch, ...routeProps } = props;

  //   console.log("estamos en private route");

  // if no auth credentials: redirect to public route
  if (!user.token) {
    console.log("There is no token. Redirecting to login...");
    return <Redirect to="/login" />;
  }

  // if auth credentials but expired: get credentials
  const tokenIsExpired =
    user.token.expirationDate < Math.floor(Date.now() / 1000);
  if (!!user.token && tokenIsExpired) {
    console.log("Token exists, but has expired...");
    console.log("Getting auth token...");

    const url = `${ROOT}/oauth/token`;
    dispatch(refreshAndSetOauthToken(url, user.token));
    return <LoadingDoggy message="Autenticando..." />;
  }

  // if auth credentials but no user data: get user data (info, doggies, ordenes, ...)
  const userDataIsIncomplete =
    Object.entries(user.userInfo).length === 0 && user.doggies.length === 0;
  if (!!user.token && !tokenIsExpired && userDataIsIncomplete) {
    console.log("token is valid but there is missing user info");
    // console.log("token before fetching user and doggies data:", user.token);
    dispatch(fetchAndSetUserAndDoggiesData(user.token));
    return <LoadingDoggy message="Buscando doggies..." />;
  }

  // if everything is ok, render component
  return <Route {...routeProps} render={props => <Component />} />;
};

const mapStateToProps = state => ({
  user: state.user
});
export default compose(connect(mapStateToProps), withRouter)(PrivateRoute);
