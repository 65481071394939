export const deleteUnnecessaryInfoFromResponse = (
  dataObj,
  arrayOfKeysToDelete
) => {
  /**elimina información innecesaria de las responses de la api call (para tener el código lo más limpio posible) */
  arrayOfKeysToDelete.forEach(item => {
    delete dataObj[item];
  });
  return dataObj;
};
