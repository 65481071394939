import { SET_SERVICES_LOAD_STATUS } from "../actions/actionTypes";

const initialState = { loadingServicesData: true };

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SERVICES_LOAD_STATUS:
      return { ...state, loadingServicesData: action.payload };
    default:
      return state;
  }
};
