import { createMuiTheme } from "@material-ui/core/styles";

const lightredmain = "#EE907B";
const darkgreymain = "#6F6F6F";
const globalopacity = "40";

const theme = createMuiTheme({
  spacing: 4,
  opacity: globalopacity,
  palette: {
    black: { main: "#231F20" },
    darkgrey: { main: darkgreymain },
    lightgrey: { main: "#DCDBDB" },
    white: { main: "#FFFFFF" },
    lightpink: { main: "#FFF0EB" },
    skin: { main: "#EFC2B3" },
    lightred: { main: lightredmain, withOpacity: `${lightredmain}${globalopacity}` },
    red: "#FF7B7E",
    lightteal: { main: "#BFEAF0" },
    teal: { main: "#7DA7AD" },
    aqua: { main: "#00E9CC" },
    primary: {
      main: "#FF7B7E",
      contrastText: "#fff"
    },
    secondary: { main: "#11cb5f" }
  },
  typography: {
    useNextVariants: true,
    fontFamily: ["Comfortaa", "Arial", "sans-serif"].join(","),
    h1: { fontFamily: "Budidaya" },
    h2: { fontFamily: "Budidaya" },
    h3: { fontSize: 48 },
    h4: { fontFamily: "Budidaya" },
    h5: { fontSize: 22 },
    h6: { color: lightredmain },
    body1: { color: darkgreymain },
    body2: { fontWeight: 700 }
  }
});

export default theme;
