import {
  SET_FECHA_FIN_CITA,
  SET_SESION_SPA,
  SET_NIVEL_ESCUELA,
  SET_SERVICIO_SELECCIONADO,
  SET_FECHA_INICIO_CITA,
  SET_TRANSPORTATION_UNIT_PRICE,
  SET_DIRECCION_SERVICIO,
  SET_DIRECCION,
  SET_CODIGO_POSTAL,
  SET_INNER_NUMBER
} from "./actionTypes";
import store from "../../store";
import {
  SUBREQUESTS_URL,
  WEBFORM_SUBMIT_URI,
  CSRF_URI,
  DOGGY_URI
} from "../../config";
import { transferDataWithSubrequests } from "../../requests";

export const setServicioSeleccionado = (
  servicio,
  transportationUnitPrice,
  direccionServicio
) => dispatch => {
  /**  cuando se selecciona un servicio en los cards de servicios en programar cita se resetea
   * la información sobre subservicios (en caso de que los haya), las fechas, y se set el servicio contratado. Esto,
   * para que no se quede información de alguna elección previa*/
  const pc = store.getState().programarCita;

  !!pc.sesionSpa && dispatch({ type: SET_SESION_SPA, payload: "" });
  !!pc.nivelEscuela && dispatch({ type: SET_NIVEL_ESCUELA, payload: "" });
  // resetear fecha de final (porque la lógica para determinar las fechas es diferente en cada caso)
  !!pc.fechaFin && dispatch({ type: SET_FECHA_FIN_CITA, payload: "" });
  !!pc.fechaInicio && dispatch({ type: SET_FECHA_INICIO_CITA, payload: "" });
  servicio = !!servicio ? servicio : "";
  dispatch({ type: SET_SERVICIO_SELECCIONADO, payload: servicio });
  transportationUnitPrice = !!transportationUnitPrice
    ? transportationUnitPrice
    : "0";
  dispatch({
    type: SET_TRANSPORTATION_UNIT_PRICE,
    payload: transportationUnitPrice
  });
  direccionServicio = !!direccionServicio ? direccionServicio : "";
  dispatch({ type: SET_DIRECCION_SERVICIO, payload: direccionServicio });
};

export const placeOrder = (orden, token, doggiesIdArr) => dispatch =>
  new Promise((resolve, reject) => {
    // doggiesIdArr: array donde cada elemento es un objecto con dos ids (id y drupalInternalId) de cada doggy de la orden
    // order: el objeto que se va a enviar como el main body de la request

    // console.log("sendable orden", orden);
    const getCSRFTokenReqId = "get-csrf-token";
    const headers = {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json",
      "X-CSRF-Token": `{{${getCSRFTokenReqId}.body}}`,
      Authorization: `${token.token_type} ${token.access_token}`
    };
    // crear un array de objetos donde cada objeto tiene como atributo el id de la request, y el objeto propiamente de la request (para publicarse)
    const publishDoggyArr = doggiesIdArr.map((doggy, i) => {
      const reqId = `publish-doggy-${i}`;
      const req = {
        requestId: reqId,
        uri: `${DOGGY_URI}/${doggy.id}`,
        body: JSON.stringify({
          data: { type: "node--doggy", id: doggy.id, attributes: { status: 1 } }
        }),
        headers,
        waitFor: [getCSRFTokenReqId],
        action: "update"
      };
      return { reqId, req };
    });
    const placeOrderReqId = "place-order";
    // crear un array de objetos donde cada objeto tiene como atributo el id de la request, y el objeto propiamente de la request (para despublicarse)
    const unpublishDoggyArr = doggiesIdArr.map((doggy, i) => {
      const reqId = `unpublish-doggy-${i}`;
      const req = {
        requestId: reqId,
        uri: `${DOGGY_URI}/${doggy.id}`,
        body: JSON.stringify({
          data: { type: "node--doggy", id: doggy.id, attributes: { status: 0 } }
        }),
        waitFor: [placeOrderReqId],
        action: "update",
        headers
      };
      return { reqId, req };
    });

    // console.log("publish doggy", publishDoggyArr);
    // console.log("unpublish doggy", unpublishDoggyArr);

    const body = JSON.stringify([
      {
        requestId: getCSRFTokenReqId,
        uri: CSRF_URI,
        action: "view"
      },
      ...publishDoggyArr.map(item => item.req),
      {
        requestId: placeOrderReqId,
        waitFor: [getCSRFTokenReqId, ...publishDoggyArr.map(it => it.reqId)],
        uri: `${WEBFORM_SUBMIT_URI}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-CSRF-Token": `{{${getCSRFTokenReqId}.body}}`,
          Authorization: `${token.token_type} ${token.access_token}`
        },
        body: JSON.stringify(orden),
        action: "create"
      },
      ...unpublishDoggyArr.map(item => item.req)
    ]);

    // console.log("body", body);
    // console.log("body (parsed)", JSON.parse(body));
    transferDataWithSubrequests(SUBREQUESTS_URL, body).then(res => {
      // console.log(res);
      resolve();
    });
  });

export const setDireccion = (type, value) => {
  // type: 'direccion' || 'codigoPostal'
  // addres: se proporciona directamente cuando se llama desde el auxiliar para el autocomplete (en este caso, no se produce un evento )
  // console.log("running ", type, value);
  const state = store.getState().programarCita;
  if (type === "direccion")
    store.dispatch({ type: SET_DIRECCION, payload: value });
  if (type === "innerNumber") {
    store.dispatch({ type: SET_INNER_NUMBER, payload: value });
  }
  if (type === "codigoPostal") {
    // para el código postal, hay que asegurarnos de que tenga la extensión correcta y de que no haya caracteres no numéricos
    const onlyNumbers = value.replace(/\D/g, "").substring(0, 5);
    store.dispatch({ type: SET_CODIGO_POSTAL, payload: onlyNumbers });
    // si se cambia el código postal y había información que depende de éste, hay que resetearla
    !!state.fechaInicio &&
      store.dispatch({ type: SET_FECHA_INICIO_CITA, payload: "" });
    !!state.fechaFin &&
      store.dispatch({ type: SET_FECHA_FIN_CITA, payload: "" });
    !!state.sesionSpa && store.dispatch({ type: SET_SESION_SPA, payload: "" });
    !!state.nivelEscuela &&
      store.dispatch({ type: SET_NIVEL_ESCUELA, payload: "" });
    !!state.servicioSeleccionado &&
      store.dispatch({ type: SET_SERVICIO_SELECCIONADO, payload: "" });
  }
};
