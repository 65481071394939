import React from "react";
import Lottie from "react-lottie";
import animationData from "../assets/french_bulldog.json";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core";

const styles = {
  loadingDoggy: {
    position: "fixed",
    width: "100%",
    height: "100%",
    left: "0",
    top: "0",
    background: "#FFF",
    verticalAlign: "middle"
  },
  loadingText: {
    textAlign: "center"
  },
  innerDiv: {
    height: "300px",
    width: "300px",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginLeft: "-150px",
    marginTop: "-190px"
  }
};

const LoadingDoggy = props => {
  const { classes, message } = props;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div className={classes.loadingDoggy}>
      <div className={classes.innerDiv}>
        <Lottie options={defaultOptions} height={300} width={300} />
        <Typography className={classes.loadingText} variant="h6">
          {message ? message : "Cargando..."}
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(styles)(LoadingDoggy);
