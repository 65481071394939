import {
  SET_HOMEPAGE_CONTENT,
  SET_SERVICIOS_CONTENT,
  SET_GALERIA_CONTENT,
  SET_INFO_PROGRAMAR_CITA
} from "./actionTypes";
import axios from "axios";
import {
  HOMEPAGE_URL,
  GALERIA_URL,
  TESTIMONIOS_URL,
  SERVICIOS_URL,
  ROOT
} from "../../config";

import { serviciosResponseToTextGraph } from "./auxiliary-functions/serviciosResponseToTextGraph";
import { serviciosContentToInfoProgramarCita } from "./auxiliary-functions/serviciosContentToInfoProgramarCita";
import { deleteUnnecessaryInfoFromResponse } from "./auxiliary-functions/cleanResponse";
import store from "../../store";

export const fetchHomePageText = () => async dispatch => {
  // get homepage data
  let resp = await axios
    .get(HOMEPAGE_URL)
    .then(response => {
      // console.log(response);
      const cleanResponse = deleteUnnecessaryInfoFromResponse(response.data, [
        "jsonapi",
        "links"
      ]);
      // console.log("clean response from fethhomepagetext", cleanResponse);
      return cleanResponse;
    })
    .catch(error => {
      console.log("error al momento de fetch homePageText", error);
    });

  // SECCIÓN DE TESTIMONIOS
  const testimonios = await axios
    .get(TESTIMONIOS_URL)
    .then(res =>
      deleteUnnecessaryInfoFromResponse(res.data, ["jsonapi", "links"])
    )
    .catch(error => {
      console.log("error al momento de fetch testimonios", error);
    });

  // SECCIÓN DE REQUISITOS
  const requisitosID = resp.data.find(
    it => it.id === "4a12ca83-6fc9-4f9b-9c79-0e1e43a3ef95"
  ).relationships.requisitos.data.id;
  const requisitosText = resp.included.find(it => it.id === requisitosID)
    .attributes.paragraph_body.processed;
  const requisitosList = resp.included
    .find(it => it.id === requisitosID)
    .attributes.requisito.map(it => it.processed);

  // SECCIÓN DE IMPORTANTE
  const importante = resp.included.find(it => it.id === requisitosID).attributes
    .importante.processed;

  // COBERTURA DE TRANSPORTE (esto no aparecerá en el homePage, pero se obtiene de aquí)
  const coberturaTransporte = resp.included
    .filter(it => it.type === "paragraph--doggy_bus_price_by_zipcode")
    .map(it => {
      let { price, title, zipcodes } = it.attributes;
      return {
        price: price.number,
        title,
        zipcodes: transformStringZipcodesToArray(zipcodes)
      };
    });
  // PRINTABLE FORM FOR PROGRAMAR CITA
  const printableFormId = resp.data[0].relationships.printable_form.data.id;
  const printableForm = `${ROOT}${
    resp.included.find(it => it.id === printableFormId).attributes.uri.url
  }`;
  // console.log("printable form id", printableFormId, printableForm);

  /** COBERTURA Y DISPONIBILIDAD DE SERVICIOS*/

  // Creamos un diccionario con los nombres que nos vienen de back y el nombre que tendrán en front para
  // trabajar con las variables asociadas a la disponibilidad
  const mapAvailabilityVarsToObject = ({
    school_with_hotel_available,
    school_with_daycare_available,
    daycare_available,
    hotel_available,
    spa_available,
    zipcodes_for_hotel_school,
    zipcodes_for_daycare_school,
    zipcodes_for_daycare,
    zipcodes_for_hotel,
    zipcodes_for_spa
  }) => {
    const availabilityObj = [
      {
        type: "escuelaConHotel",
        isAvailable: school_with_hotel_available,
        zcAvailability: transformStringZipcodesToArray(
          zipcodes_for_hotel_school
        )
      },
      {
        type: "escuelaConGuarderia",
        isAvailable: school_with_daycare_available,
        zcAvailability: transformStringZipcodesToArray(
          zipcodes_for_daycare_school
        )
      },
      {
        type: "guarderia",
        isAvailable: daycare_available,
        zcAvailability: transformStringZipcodesToArray(zipcodes_for_daycare)
      },
      {
        type: "hotel",
        isAvailable: hotel_available,
        zcAvailability: transformStringZipcodesToArray(zipcodes_for_hotel)
      },
      {
        type: "spa",
        isAvailable: spa_available,
        zcAvailability: transformStringZipcodesToArray(zipcodes_for_spa)
      }
    ];
    return availabilityObj;
  };
  const attrs = resp.data.find(item => item.type === "node--homepage")
    .attributes;
  // console.log(attrs);
  const serviceAvailability = mapAvailabilityVarsToObject(attrs);

  // quitar atributos duplicados (por ejemnplo, porque se trabajaron y asignaron a otro lugar)
  const attrsToRemoveFromResp = [
    "school_with_hotel_available",
    "school_with_daycare_available",
    "daycare_available",
    "hotel_available",
    "spa_available",
    "zipcodes_for_hotel_school",
    "zipcodes_for_daycare_school",
    "zipcodes_for_daycare",
    "zipcodes_for_hotel",
    "zipcodes_for_spa"
  ];
  resp.data.find(
    item => item.type === "node--homepage"
  ).attributes = Object.keys(attrs)
    .filter(key => !attrsToRemoveFromResp.includes(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: attrs[key]
      };
    }, {});

  const objToRedux = {
    ...resp,
    serviceAvailability,
    testimonios,
    requisitosText,
    requisitosList,
    importante,
    coberturaTransporte,
    printableForm
  };

  dispatch({
    type: SET_HOMEPAGE_CONTENT,
    payload: objToRedux
  });
};

export const fetchServiciosText = () => dispatch =>
  new Promise(resolve => {
    axios
      .get(SERVICIOS_URL)
      .then(response => {
        // transform response so it has a manageable structure for redux
        const serviciosContent = serviciosResponseToTextGraph(response);
        dispatch({
          type: SET_SERVICIOS_CONTENT,
          payload: serviciosContent
        });
      })
      .then(() => {
        resolve();
      })
      .catch(error => {
        console.log("error al momento de fetch serviciosText", error);
      });
  });

/**GALERÍA */
export const fetchGaleriaText = () => dispatch =>
  new Promise(resolve => {
    console.log("in fetchGaleriaText");
    axios
      .get(GALERIA_URL)
      .then(resp => {
        // transform response so it has a manageable structure for redux
        console.log("Resp: ", resp.data.included);
        const imagesIds = resp.data.data
          .find(it => it.id === "4a12ca83-6fc9-4f9b-9c79-0e1e43a3ef95")
          .relationships.galeria.data.map(item => item.id);
        // console.log(imagesIds);
        const imageFilesIds = resp.data.included
          .filter(item => imagesIds.includes(item.id))
          .map(item => {
            const alt = item.relationships.imageFile.data.meta.alt;
            const id = item.relationships.imageFile.data.id;
            return { id, alt };
          });
        const imagenesGaleria = imageFilesIds.map(img => {
          const fileObj = resp.data.included.find(it => it.id === img.id);
          console.log("fileObj", fileObj);
          // inlcuir las rutas para webp
          const webpTh = fileObj.links.webp.href;
          const webpXS = fileObj.links[384].href;
          const webpS = fileObj.links[768].href;
          const webpM = fileObj.links[1200].href;
          const webpL = fileObj.links[1920].href;
          // erase me const webpMedium = fileObj.links.medium.href;
          // incluir la ruta para el caso fallback (cuando el browser no tiene soporte para webp)
          const jpg = `${ROOT}${fileObj.attributes.uri.url}`;
          const jpgTh = fileObj.links.thumbnail.href;
          const returnObj = {
            webpTh,
            webpXS,
            webpS,
            webpM,
            webpL,
            // erase me webpMedium,
            alt: img.alt,
            jpg,
            jpgTh
          };
          return returnObj;
        });
        // console.log("files", imagenesGaleria);
        
        dispatch({
          type: SET_GALERIA_CONTENT,
          payload: imagenesGaleria
        });
      })
      .then(() => {
        resolve();
      })
      .catch(error => {
        console.log("error al momento de fetch galeriaText", error);
      });
  });

export const setInfoProgramarCita = () => dispatch => {
  const staticContent = store.getState().staticContent;
  const infoProgramarCita = serviciosContentToInfoProgramarCita(staticContent);
  dispatch({ type: SET_INFO_PROGRAMAR_CITA, payload: infoProgramarCita });
};
// AUXILIARY FUNCTIONS
const transformStringZipcodesToArray = zcString => {
  return JSON.stringify(zcString)
    .split("\\r\\n")
    .map(it => it.replace('"', ""));
};
